@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '';
  color: transparent;
  background: #e92c40;
  height: 1px;
}
div.stroke ul li a:hover:after {
  width: 100%;;
}

.btn-primary {
  background-color: white;
  border-color: white;
  color: black;
  border-radius: 25px;
  padding: 4px 15px;
}

/* .btn-primary:hover,.btn-primary:focus,.btn-primary:active {
  background-color: #e92c40;
  border-color: #e92c40;
}  */

.btn-primary a {
  color: black;
}
.btn-primary:hover,.btn-primary:focus,.btn-primary:active {
 background-color: lightgray;
 border-color: lightgray;
}

html, body {
  height:100%;
  margin:0;
  padding:0;
  font-family: 'Didact Gothic', sans-serif;
  }

p {
  margin: 0 0 30px ;
}

ul {
  text-transform: uppercase;
}

nav ul li a,
nav ul li a:after
{
  transition: all .5s;
}

#ac-nav-dark a{
  color: black;
}

#ac-nav a{
  color: white;
}

.central-logo {
  position: fixed;
  top: 37vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  height: auto;
  /* margin-bottom: 15vh; */
}

.carousel, .item, .active {
height:100%;
}

.carousel-inner {
height:100vh;
}

.carousel-caption {
z-index: 10;
}

.carousel .item {
background-color: #777;
}

.carousel .carousel-inner .bg {
background-repeat:no-repeat;
background-size:cover;
}

.carousel .carousel-inner .bg1 {
  background-image:
  linear-gradient(
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.7)),
    url(/static/media/intro1.9c4e310b.jpg);
background-position: center top;
}

.carousel .carousel-inner .bg2 {
  background-image:
  linear-gradient(
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.7)),
    url(/static/media/intro2.15ef1d43.jpg);
    background-position: center center;
}

.carousel .carousel-inner .bg3 {
  background-image:
  linear-gradient(
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.8)),
    url(/static/media/intro3.285f863b.jpg);
    background-position: center bottom;
}

.dropdown-menu {
  background-color: transparent;
  text-align: center;
  border: none;
  box-shadow: none;
  min-width: 80px;
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, 0);
}

.dropdown-menu>li>a:hover {
  color: #e92c40 !important;
}

.landing-model-page {
  width: 100%;
  position: relative;
  top:-70px
}

.landing-text {
  position:fixed;
  z-index:99999999999;
  top:20%;
  left:10%;
  right:50%;
}

.nav>li>a:focus, .nav>li>a:hover {
  text-decoration: none;
  background-color: transparent !important;
}

.navbar {
  position: absolute;
  min-height: 50px;
  z-index: 999;
  width: 100%;
  margin-bottom: 0;
}

.navbar-brand {
  padding: 5px 5px !important;
}

.navbar-collapse {
  box-shadow: none;
}

/* TO CENTER NAVBAR ELEMENTS*/
/* .navbar-nav {
  float: inherit;
} */

.notre-societe-bg {
  height: 100%;
  background-image:linear-gradient(
    rgba(0, 0, 0, 0.2), 
    rgba(0, 0, 0, 0.3)),
    url(/static/media/landing.669fd9f6.jpg);
  background-repeat:no-repeat;
  background-size:cover;
  position: fixed;
}

.not-found-text {
      margin: auto;
      padding-top: 40vh;
      height: 100vh;
      font-size: 7vw;
      text-transform: capitalize;
}

.no-match {
  background-image:linear-gradient(
    rgba(0, 0, 0, 0.2), 
    rgba(0, 0, 0, 0.3)),
    url(/static/media/maintainment.699c0438.jpg);
  background-repeat:no-repeat;
  background-size:cover;
}
h1 {
  font-size: 30px;
} 
.textHover:hover {
  color: #e92c40 !important;
}

@media (min-width: 769px) {
  ul {
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 768px) {
  ul {
    text-align: center;
  }
  .central-logo {
    /* display: none; */
    /* padding-bottom: 40vh; */
    top: 30%;
    width: 65vw;
  }

  .dropdown-menu {
    transform: none;
  }
  .landing-model-page {
    top:0
  }
  .landing-text{
    left: 3%;
    right: 40%;
  }
  h1 {
    margin-top: -40px;
    font-size: 29px;
  }
  .navbar-nav{
    background-color: rgba(4,0,0,0.6);
  }
  #ac-nav a{
    color: black;
  }
}

.footer-ul ul {
  display: block;
}

#myFooter {
  background-color: #3c3d41;
  color: white;
  padding-top: 30px;
}

#myFooter .footer-copyright {
  background-color: #333438;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
}

#myFooter .row {
  margin-bottom: 60px;
}

#myFooter .navbar-brand {
  margin-top: 45px;
  height: 65px;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #ccc;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 30px;
}

#myFooter h2 a{
  font-size: 50px;
  text-align: center;
  color: #fff;
}

#myFooter a {
  color: #d2d1d1;
  text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  text-decoration: none;
  color: red;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

#myFooter .social-networks a {
  font-size: 32px;
  color: #f9f9f9;
  padding: 10px;
  transition: 0.2s;
}

#myFooter .social-networks a:hover {
  text-decoration: none;
}

#myFooter .facebook:hover {
  color: #0077e2;
}

#myFooter .google:hover {
  color: #ef1a1a;
}

#myFooter .twitter:hover {
  color: #00aced;
}

#myFooter .btn {
  color: white;
  background-color: #d84b6b;
  border-radius: 20px;
  border: none;
  width: 150px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  #myFooter {
      text-align: center;
  }
}

.landing-content {
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 8vw;
  justify-content: center;
}
.snip1401 {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  text-align: right;
  font-size: 16px;
}
.snip1401 * {
  box-sizing: border-box;
  /* -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; */
}
.snip1401 img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.snip1401 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
  padding: 30px 0 30px 10px;
  background-color: #ffffff;
  width: 58%;
  transform: translateX(150%);
}
.snip1401 figcaption:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50vh 27vw 50vh 1vw;
  border-color: transparent #ffffff transparent transparent;
}
.snip1401:after {
  position: absolute;
  bottom: 50%;
  right: 40%;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50vh 45vw 0 27vw;
  border-color: rgba(255, 255, 255, 0.5) transparent transparent transparent;
  transform: translateY(-50%);
  /* -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease; */
}
@media (max-width: 767px) {
  .snip1401:before {
    right: 50%;
    border-width: 50vh 45vw 0 25vw;
  }
  .snip1401:after {
    right: 50%;
    border-width: 50vh 45vw 0 25vw;
  }
  .snip1401 figcaption {
    width: 68%;
  }
  .landing-content h4{
    font-size: 14px;
  }
}

.snip1401 h3,
.snip1401 p {
  line-height: 1.5em;
  transform: translateX(-30px);
  margin: 0;
}
.snip1401 h3 {
  margin: 0 0 5px;
  line-height: 1.1em;
  font-weight: 900;
  font-size: 1.4em;
  opacity: 0.75;
}
.snip1401 p {
  font-size: 0.8em;
}
.snip1401 i {
    /* left: 0; */
    padding: 0px 15px;
    font-size: 20px;
}
.snip1401 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.snip1401:hover img,
.snip1401.hover img {
  zoom: 1;
  filter: alpha(opacity=50);
  -webkit-opacity: 0.5;
  opacity: 0.5;
}
.snip1401:hover:after,
.snip1401.hover:after,
.snip1401:hover figcaption,
.snip1401.hover figcaption,
.snip1401:hover i,
.snip1401.hover i {
  transform: translateX(0);
  opacity: 1;
}
